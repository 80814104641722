<template lang="pug">
  .select-all(@click="selectToggle")
    span {{ selectText }}
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  props: {
    activeItemsCount: {type: Number, default: 0},
    itemsToSelectCount: {type: Number, default: 0},
  },

  mixins: [errorsMixin],

  computed: {
    allSelected() {
      return this.itemsToSelectCount === this.activeItemsCount;
    },
    selectText() {
      return this.allSelected ? `Unselect all` : `Select up to ${this.itemsToSelectCount}`
    }
  },

  data: () => ({
    maxAbleToSelect: 1000
  }),

  methods: {
    async selectToggle() {
      if (this.allSelected) this.$emit('change', {clear: true})
      else {
        if (this.itemsToSelectCount > this.maxAbleToSelect) {
          this.showErrorModal(`Cannot select more than ${this.maxAbleToSelect}`)
          return
        }
        this.$emit('change', {clear: false})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-all {
  display: flex;
  white-space: nowrap;
  align-items: center;
  color: $primary-color;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  @include media("<=tablet") {
    font-size: 12px;
  }
}
</style>
